import React from 'react';
export const Nav30DataSource = {
  wrapper: { className: 'header3 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header3-logo',
    children:
      './images/yiqun-logo.png',
  },
  Menu: {
    className: 'header3-menu',
    children: [
      {
        name: 'item0',
        className: 'header3-item',
        children: {
          href: '',
          children: [
            {
              children: (
                <span>
                  <p>首页</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item1',
        className: 'header3-item',
        children: {
          href: '#Feature5_0',
          children: [
            {
              children: (
                <span>
                  <p>产品业务</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item2',
        className: 'header3-item',
        children: {
          href: '#Content5_0',
          children: [
            {
              children: (
                <span>
                  <p>行业案例</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
      {
        name: 'item3',
        className: 'header3-item',
        children: {
          href: '#Feature4_1',
          children: [
            {
              children: (
                <span>
                  <p>关于我们</p>
                </span>
              ),
              name: 'text',
            },
          ],
        },
      },
    ],
  },
  mobileMenu: { className: 'header3-mobile-menu' },
};
export const Banner00DataSource = {
  wrapper: { className: 'banner0 kwcxkgvkw3j-editor_css' },
  textWrapper: { className: 'banner0-text-wrapper kwcxkfuto5-editor_css' },
  title: {
    className: 'banner0-title kwcxxkchxnj-editor_css',
    children: 'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png',
  },
  content: {
    className: 'banner0-content kwcxxqkz17o-editor_css',
    children: '一个高效的页面动画解决方案',
  },
  button: { className: 'banner0-button', children: 'Learn More' },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [{ name: 'title', children: '产品与服务' }],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                './images/shexiangtou.svg',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <p>安防监控</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>提供多类型的智能安防监控设备，满足不同的客户需求</p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon kwd4q6d0fk-editor_css',
              children:
                './images/icon_tongxun.svg',
            },
            {
              name: 'title',
              className: 'content0-block-title kwd4q6d0fk1-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <p>信息通讯</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>弱电工程、综合布线、信息网络等提供综合IT解决方案</p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                './images/icon_it.svg',
            },
            {
              name: 'title',
              className: 'content0-block-title',
              children: (
                <span>
                  <span>
                    <p>IT服务</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>IT系统外包开发、IT信息化规划咨询、项目管理咨询</p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Feature50DataSource = {
  wrapper: { className: 'home-page-wrapper content7-wrapper' },
  page: { className: 'home-page content7' },
  OverPack: {},
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>蚁群传奇智能科技核心业务</p>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>满足工程品质 · 精算师严控预算 · 超前布局满足未来10年发展</p>
          </span>
        ),
      },
    ],
  },
  tabsWrapper: { className: 'content7-tabs-wrapper' },
  block: {
    children: [
      {
        name: 'block0',
        tag: {
          className: 'content7-tag',
          text: {
            children: (
              <span>
                <p>安防监控</p>
              </span>
            ),
            className: 'content7-tag-name',
          },
          icon: { children: 'mobile' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <span>
                            <span>
                              <span>
                                <h3>
                                  AI智能监控<br />
                                </h3>
                                <p>
                                  智能分析与巡检主动防御，智能化、大数据新功能。支持数百种算法，
                                  广泛应用在园区、工厂、化工园、加油站、政务大厅等场景，覆盖众多细分行业，拥有多行业解决方案及智能应用。
                                </p>
                                <p>
                                  <br />
                                </p>
                                <p>
                                  <br />
                                </p>
                                <h3>
                                  高清视频监控<br />
                                </h3>
                                <p>高清装备，白天黑夜始终高清。监控系统采用了目前国际上最先进的网络传输技术和H.264视频压缩技术完美结合，
                                  通过前端高清视频监控设备采集数据并传输至后端存储显示和管理控制，实现对监视设防区域的有效取证及管理和打击违法犯罪行为</p>
                                <p>
                                  <br />
                                </p>
                                <p>
                                  <br />
                                </p>
                                <h3>
                                  防爆视频监控<br />
                                </h3>
                              </span>
                            </span>
                          </span>
                        </span>防潮、防尘、防腐防雷、防搅扰<span>
                          <span>
                            <span>
                              <span>。</span>
                            </span>
                          </span>
                        </span>
                      </span>
                      <p>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <br />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </p>
                      <p>
                        <span>
                          <span>
                            <span>
                              <span>
                                <span>
                                  <br />
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </p>
                      <h3>
                        无线视频监控<br />
                      </h3>
                      <p>低成本、部署方便、灵活性高。</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            children:
              './images/icon_shexiangtou.jpeg',
            md: 10,
            xs: 24,
          },
        },
      },
      {
        name: 'block1',
        tag: {
          className: 'content7-tag',
          icon: { children: 'tablet' },
          text: {
            className: 'content7-tag-name',
            children: (
              <span>
                <p>网络布线</p>
              </span>
            ),
          },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <span>
                  <span>
                    <h3>
                      专注于综合布线技术<br />
                    </h3>
                  </span>
                  <p>
                    所有网络综合产品均为原厂正品，提供原厂授权，假一赔十。<span>
                      我司拥有1000余家品牌战略商，专业提供产品服务及技术支持。
                    </span>
                    <span>所有网络点均须通过验收要求，并提供测试报告。</span>
                    <span>经验丰富的施工队伍与项目经理，保证施工质量</span>
                    <span>。</span>
                  </p>
                  <span>
                    <br />
                    <h3>
                      量身定制 提供最佳方案<br />
                    </h3>
                    <p>
                      网络综合布线是否符合计算机网络、通信络的基本规律？<span>
                        布线系统物理结构是否合理？
                      </span>
                      <span>系统是否有可发展、可扩充的余地？</span>
                      <span>网络综合布线是否具有较好性能/价格比？</span>
                    </p>
                    <br />
                    <h3>
                      不放过每个细节 工程质量有保证<br />
                    </h3>
                  </span>
                </span>
                <p>
                  管道预埋均匀 合理架构连接紧密 护口齐全，整齐美观。<span>
                    网络均采用100%原装正品线缆，沿桥架或管道敷设，排列整齐。
                  </span>
                  <span>
                    机柜安装统一摆放空间。机柜内的理线整齐排列，便于查找，售后。
                  </span>
                  <span>
                    信息点信噪比、串扰、永久链路符合国际标准保证链路贯通。
                  </span>
                </p>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            children:
              './images/icon_buxian.jpeg',
          },
        },
      },
      {
        name: 'block2',
        tag: {
          className: 'content7-tag',
          text: {
            children: (
              <span>
                <p>智慧建筑</p>
              </span>
            ),
            className: 'content7-tag-name',
          },
          icon: { children: 'laptop' },
        },
        content: {
          className: 'content7-content',
          text: {
            className: 'content7-text',
            md: 14,
            xs: 24,
            children: (
              <span>
                <span>
                  <h3>
                    智慧楼宇<br />
                  </h3>
                  <p>
                    整合位置感知、人脸识别等设备数据，实时监控区域内人流信息、车辆进出信息以及重要资产的位置信息，保证其正常运转。
                    同时提供基于该区域的实时位置导航服务。为用户提供安全、便捷的周边服务。以智能化和数字化标准为依据，为各种业态建筑提供服务和人性化环境。
                  </p>
                  <br />
                  <h3>
                    智慧园区<br />
                  </h3>
                  <p>
                    优化访客预约流程、智能考勤管理、丰富预案库，依托蓝牙、WiFi定位提供进出全新体验，实现闸机、电梯等设备基于用户位置的智能调度，
                    催生现代智慧服务新产业、新技术、新模式、新业态，提升园区服务效率和管理水平为用户提供全新的进出办公体验。
                  </p>
                  <br />
                  <h3>
                    智慧医疗<br />
                  </h3>
                </span>利用互联网、云计算、远程音视频互动等技术建立完善的远程医疗机制及平台，对于目前国家大力推进的分级诊疗普及、医疗资源的互联共享、
                偏远地区的医疗水平提升、实现医疗公平与医疗体系数字化已经成为从政府到医疗卫生单位及整个社会发展的大势所趋。通过搭建起智慧化的远程医疗云平台，
                打造智能楼宇+智慧医疗双融合信息化系统解决方案。<span
                >
                  。
                </span>
              </span>
            ),
          },
          img: {
            className: 'content7-img',
            md: 10,
            xs: 24,
            children:
              './images/icon_zhihuijiazhu.jpeg',
          },
        },
      },
    ],
  },
};
export const Feature40DataSource = {
  wrapper: { className: 'home-page-wrapper content6-wrapper' },
  OverPack: { className: 'home-page content6 kwcwtmbvlw-editor_css' },
  textWrapper: { className: 'content6-text', xs: 24, md: 10 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>十余年弱电工程经验沉淀</p>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <span>
              <p>服务上百个大中型企业</p>
            </span>
          </span>
        ),
      },
    ],
  },
  img: {
    children: './images/icon_tuandui.jpeg',
    className: 'content6-img',
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children:
            './images/icon_kuai.svg',
          className: 'content6-icon',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <p>由专业团队全程操作</p>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>1:1按方案执行 提效30%</p>
            </span>
          ),
        },
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon',
          children:
            './images/icon_jing.svg',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <p>选用世界品牌产品</p>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>先进设备深层检测分析隐患</p>
            </span>
          ),
        },
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon',
          children:
            './images/icon_xin.svg',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <p>超前设计 布局智能化</p>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>掌握智能化监控核心技术资源</p>
            </span>
          ),
        },
      },
      {
        name: 'block~kwcw6gb5wgt',
        img: {
          className: 'content6-icon',
          children:
            './images/icon_wen.svg',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <span>
                <p>严格遵循国标作业体系</p>
              </span>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children: (
            <span>
              <p>36道精细化工序面面俱到</p>
            </span>
          ),
        },
      },
    ],
  },
};
export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    className: 'kwd6eaxxzpk-editor_css',
    children: './images/icon_bangong.svg',
  },
  textWrapper: {
    className: 'content1-text kwcx4vd76rn-editor_css',
    md: 14,
    xs: 24,
  },
  title: {
    className: 'content1-title kwcx3tlw6tj-editor_css',
    children: (
      <span>
        <p>蚁群传奇智能科技（天津）有限公司</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content',
    children: (
      <span>
        <span>
          <span>
            <span>
              <span>
                <p>
                  <span>
                    我司是一家专业以智能化弱电工程为主的高科技民营企业，公司自创立以来一直专业致力于智能化弱电工程；始终坚持发扬"诚信、创新、沟通"为企业宗旨，以"技术、服务"为立业之本的团体精神，并形成一套完整的设计、安装、调试、培训、维护一站式服务体系。其服务内容包括：安防监控、弱电综合布线、门禁对讲、无线覆盖、智能化小区综合物业管理系统工程、弱电外包维护、升级、改造等。
                  </span>
                  <br />
                </p>
              </span>
            </span>
          </span>
        </span>
      </span>
    ),
  },
};
export const Feature41DataSource = {
  wrapper: { className: 'home-page-wrapper content6-wrapper' },
  OverPack: { className: 'home-page content6' },
  textWrapper: { className: 'content6-text', xs: 24, md: 10 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <p>联系我们</p>
              </span>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content',
        children: (
          <span>
            <p>欢迎来电，非常期待与您的合作</p>
          </span>
        ),
      },
    ],
  },
  img: {
    children: './images/icon_address.png',
    className: 'content6-img',
    xs: 24,
    md: 14,
  },
  block: {
    children: [
      {
        name: 'block0',
        img: {
          children:
            'https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png',
          className: 'content6-icon',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <p>客服电话</p>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children:
            '13021397531',
        },
      },
      {
        name: 'block1',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <p>电子邮箱</p>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children:
            'service@antlegend.net',
        },
      },
      {
        name: 'block2',
        img: {
          className: 'content6-icon',
          children:
            'https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png',
        },
        title: {
          className: 'content6-title',
          children: (
            <span>
              <p>公司地址</p>
            </span>
          ),
        },
        content: {
          className: 'content6-content',
          children:
            '天津市南开区密云一支路新南马路五金城三区25栋406',
        },
      },
    ],
  },
};
export const Content50DataSource = {
  wrapper: { className: 'home-page-wrapper content5-wrapper' },
  page: { className: 'home-page content5 kwcwuy5humd-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      { name: 'title', children: '客户案例', className: 'title-h1' },
      {
        name: 'content',
        className: 'title-content kwcwvh4klye-editor_css',
        children: (
          <span>
            <span>
              <span>
                <p>众多高要求客户优选蚁群传奇智能科技</p>
              </span>
            </span>
          </span>
        ),
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              './images/case/meijiang.jpeg',
          },
          content: {
            children: (
              <span>
                <p>梅江会展中心</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              './images/case/haitaichuangxin.jpeg',
          },
          content: {
            children: (
              <span>
                <p>海泰创新基地</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              './images/case/haitaifazhan.png',
          },
          content: {
            children: (
              <span>
                <p>海泰发展基地</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              './images/case/haitailvse.png',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>海泰绿色基地</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              './images/case/binhairuanjyuan.jpeg',
          },
          content: {
            children: (
              <span>
                <p>滨海软件园</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              './images/case/wangjing.jpeg',
          },
          content: {
            children: (
              <span>
                <p>SOHO</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block6',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              './images/case/haitai.jpeg',
          },
          content: {
            children: (
              <span>
                <p>海泰大厦</p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block7',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              './images/case/zhongyiyao.png',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>中医药大学附属医院</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block8',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              './images/case/icon_wujing.jpeg',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>天津武警医院</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block9',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              './images/case/icon_wumei.jpeg',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>物美超市</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block10',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              './images/case/icon_xiyuehui.jpeg',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>熙悦汇</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block11',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              './images/case/icon_shehuishsan.jpeg',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>智慧山</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block12',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              './images/case/icon_tianyoucheng.jpeg',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>天佑城</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block13',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              './images/case/icon_renmin.jpeg',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>天津人民医院</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block14',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              './images/case/icon_guwenhuajing.png',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>古文化街</p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block15',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children:
              './images/case/icon_zhaoshang.png',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>河西招行银行总行</p>
                </span>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Content120DataSource = {
  wrapper: { className: 'home-page-wrapper content12-wrapper' },
  page: { className: 'home-page content12' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'image',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg',
        className: 'title-image',
      },
      { name: 'title', children: '特别鸣谢', className: 'title-h1' },
    ],
  },
  block: {
    className: 'img-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/TFicUVisNHTOEeMYXuQF.svg',
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/hkLGkrlCEkGZeMQlnEkD.svg',
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/bqyPRSZmhvrsfJrBvASi.svg',
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/UcsyszzOabdCYDkoPPnM.svg',
          },
        },
      },
      {
        name: 'block4',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/kRBeaICGexAmVjqBEqgw.svg',
          },
        },
      },
      {
        name: 'block5',
        className: 'block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'block-content' },
          img: {
            children:
              'https://gw.alipayobjects.com/zos/rmsportal/ftBIiyJcCHpHEioRvPsV.svg',
          },
        },
      },
    ],
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <span>
          <span>
            ©2022 <a href="https://www.antlegend.net">蚁群传奇智能科技（天津）有限公司</a> All Rights Reserved | <a href="https://beian.miit.gov.cn" style={{ color: '#999999' }}>津ICP备2021009868号-1</a>
          </span>
        </span>
      </span>
    ),
  },
};
